import React, {Component} from 'react';

export default class Termcondition extends Component {
render(){
return (

<div className="shop-section section pt-30 pt-lg-30 pt-md-60 pt-sm-50 pt-xs-45 pb-70 pb-lg-50 pb-md-40 pb-sm-60 pb-xs-50">
<div className="container">

<div class="row">
<div class="col">
<div class="section-title mb-20 mb-xs-20 text-center">
<h2 className="oursolutionleafbg" style={{'fontWeight':'600','color':'#db9c28','marginTop':'45px'}}>TERMS AND CONDITIONS</h2>
</div>
</div>
</div>


<div className="row">
<div className="col-12">
<p class="prod-title ptag" style={{'lineHeight':'20px'}}>This document is an electronic record in terms of the Information Technology Act, 2000 and rules made thereunder and as the same may be amended from time to time. Being a system generated electronic record, it does not require any physical or digital signature.</p>
<p class="prod-title ptag" style={{'lineHeight':'20px'}}>
Greetings from <strong style={{'color':'#003628'}}>www.theorganicforest.in</strong>. The Website is owned by <strong style={{'color':'#003628'}}>TORFO wellness private limited</strong>, a company incorporated under the Companies Act, 2013 having <strong style={{'color':'#003628'}}>CIN U74999MH2017PTC296663</strong>, and having its registered address at 306, SSR Corporate Park, Mathura Road, Faridabad, Haryana ("The Organic Forest" or “we” or “our”).
</p>

<p class="prod-title ptag" style={{'lineHeight':'20px'}}>
If you use the Website, you are required to first carefully read as well as fully understand the following Website related terms and conditions (“Terms of Use”) which are applicable when you view/access/use (collectively “use” or “using”) the Website from any computer, computer device or mobile. Please also carefully and fully understand read our Privacy Policy available at the link <strong style={{'color':'#003628'}}>(“Privacy Policy”)</strong>, which is incorporated in these Terms of Use by reference.
</p>
<p class="prod-title ptag" style={{'lineHeight':'20px'}}>
If, for any reason, you do not agree to these <strong style={{'color':'#003628'}}>Terms of Use</strong>, <strong style={{'color':'#003628'}}>Privacy Policy</strong> and/or other Website guidelines and policies (Website guidelines and policies collectively referred to as “Other Policies”) as communicated to you when you use the Website or wish to be bound by any of them, please do not use the Website in any way whatsoever. By using the Website, you have indicated to us that you acknowledge, understand as well as fully agree, to be bound by these Terms of Use, Privacy Policy and all Other Policies, irrespective whether you register with the Website or not.
</p>

<p class="prod-title ptag" style={{'lineHeight':'20px'}}>
As long as you adhere to the Terms of Use, Privacy Policy and Other Policies, we grant you a personal, revocable, non-exclusive, non-transferable and limited right and license to use the Website.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>1. Acceptance of Terms, etc
</strong></p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> These Terms of Use is in the form of an electronic and legally binding contract that establishes the terms and conditions you have accepted before using the Website. These include our Privacy Policy and Other Policies as mentioned in these Terms of Use as well as other specific policies and terms and conditions disclosed to you, in case you avail any subscription or any additional features, products or services we offer on or through the Website, whether free or otherwise, including but not limited to terms governing features, billing, free trials, discounts, promotions, refunds, cancellations and shipping. By using the Website, you hereby unconditionally consent and accept to these Terms, Privacy Policy and such Other Policies. To withdraw such consent, you must immediately cease using the Website and terminate your account, if any, with us. You are requested to keep a physical copy of these Terms of Use and all other referred policies herein for your records.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>b.</strong> Every time you use the Website, you confirm your express agreement with our Terms of Use, Privacy Policy and Other Policies. 
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>2. Eligibility
</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> Use of the Website is available only to persons who can form legally binding contracts under Indian Contract Act, 1872 and who have attained a minimum age of 18 (eighteen) years. By using the Website, you represent and warrant that you are at least 18 (eighteen) years of age. As a minor if you wish to use or make any transaction on the Website, such use or transaction may be made by you through your parents or legal guardian. We reserve the right to terminate your membership and/or refuse to provide you with access to the Website if it is brought to our notice or if it is discovered that you are under the age of 18 (eighteen) years.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>b.</strong> By using the Website, you hereby represent and warrant to us that you have all right, authority and capacity to enter into these Terms of Use and to abide by all of the terms and conditions there of.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>c.</strong> Unless otherwise specified, the various products showcased on the Website are currently offered for sale in India only. We make no representation that such products will be sold or delivered in any other jurisdiction. Those who choose to access the Website from outside India can do so on their own account and we shall not be responsible for any circumstances there of.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>3. Creating an Account</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> In order to use all the features of the Website, you must register yourself with the Website. If you do not wish to register, all of the Website features may not be available to you. When using the Website you authorize us to access and use certain account information of yours, including but not limited to your profile information your interests and dislikes and other information as detailed under our Privacy Policy. For more information regarding the type/nature of information we collect from you and how we use it, please refer our Privacy Policy at the link.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>b.</strong> You shall be responsible for maintaining the utmost privacy and confidentiality of your membership and account details you provide to us or on the Website and the same must always be kept private and confidential and should not be disclosed to or permitted to be used by anyone else and you are solely responsible and liable for any and all usage and activity on the Website that takes place under your account. You agree to immediately notify us of any disclosure or unauthorized use of your Website account details or any other breach of security at and ensure that you successfully log-out from your Website account at the end of each session. Further, you agree that if you provide any information that is untrue, incomplete, inaccurate, or not updated or we have reasonable grounds to suspect that such information is untrue, incomplete, inaccurate or not updated, or not in accordance with this Terms of Use, we shall have the right to temporarily suspend or permanently terminate your Website membership/access.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>c.</strong> By agreeing to these Terms of Use, you grant us the permission to send electronic communications to you as part of our offering. This includes but is not limited to sending emails, newsletters and promotional offers from us and our partner websites. Should you no longer wish to receive these electronic communications, you may write to us at.</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>4. Proprietary Rights</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> You confirm and agree that we and/or our vendor partners are the owners of the proprietary information made available to you through the Website and hereby retain all proprietary rights in the same.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>b.</strong> We may upload/publish/post any copyrighted information and materials on the Website, whether having copyright protection or not and whether bearing any trademark or not, or which may be identified as copyright or a trademark. You undertake not to post, copy, reproduce, manufacture, modify, publish, transmit, distribute, perform, display, commercially use/exploit, sell or use such information or materials in any way, whether partly or fully, and for any purpose whatsoever.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>c.</strong> By posting information or content to any permitted area of the Website, or making it accessible to us by linking your account to any social network accounts (e.g. via Facebook, etc.), you grant us unconditionally and in perpetuity, and represent and warrant that you have the right to grant to us, an irrevocable, perpetual, non-exclusive, fully-paid/royalty-free, worldwide right and license to use, reproduce, store, publicly display and distribute such information and content, and to prepare derivative works of, or incorporate into other works, such information and content, and to grant and authorize sub-licenses of the foregoing anywhere in the world. From time to time, we may modify, add or vary existing features or programs of the Website or create, add, test or implement new features or programs on the Website in which you may voluntarily choose to participate or may be a part of a test group with special access, in accordance with the additional terms and conditions of such features or programs. By participating in such features or programs, you grant us an unconditional and perpetual right and consent to the terms and conditions (if any) of such features or programs which will be in addition to these Terms of Use.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>5. Website Charges</strong> 
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
Membership on the Website is free. The Organic Forest does not charge any fee for browsing and making any purchase from the Website. However, The Organic Forest expressly reserves its right to levy a fee from time to time. If any such fee is levied, The Organic Forest will post the same on the Website and such fees shall automatically become effective immediately after they are posted on the Website. Unless otherwise stated, all fees shall be quoted in Indian Rupees. You shall be solely responsible for compliance of all applicable laws for making payments to The Organic Forest.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>6. Prohibited Activities
</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
The Organic Forest reserves its right to investigate, suspend and/or terminate, whether temporarily or permanently, your Website account if you undertake any of the following acts:
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> Breach these Terms of Use, Privacy Policy or Other Policies.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>b.</strong> Abuse, impersonate or defame any Website user or any other person, entity or any religious community.</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>c.</strong> Displaying, publishing, transmitting or sharing, if permitted to do so by The Organic Forest, any information or content that is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>d.</strong> Displaying, publishing, transmitting or sharing any information or content that is misleading in any way.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>e.</strong> Use the Website for any commercial use or activity not expressly permitted under these Terms of Use.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>f.</strong> “Stalk” or otherwise harass any Website user or any other person;
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>g.</strong> Make any statements, whether expressed or implied, and whether privately or publicly, as those endorsed by us without our specific prior written consent;
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>h.</strong> Use the Website in an illegal manner or commit an illegal act or use the Website not expressly authorized by us;
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>i.</strong> Making use of any information or content from the Website that or violates or infringes or of any third party's rights (including, but not limited to, intellectual property rights, rights of privacy or rights of publicity).
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>j.</strong> Use any robot, spider, tool, site search/retrieval application, or other manual or automatic device or process to retrieve, index, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Website.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>k.</strong> Collect any personal information, including contact details, of any Website users by electronic or any other means and for any purpose, not expressly permitted under these Terms of Use.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>l.</strong> Send any unsolicited email or any other communication in any way whatsoever not expressly permitted under these Terms of Use.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>m.</strong> Undertake any unauthorized framing of or linking to the Website or “frame” or “mirror” any part of the Website, without our prior written authorization.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>n.</strong> Interfere with, obstruct, destroy or disrupt the Website or the servers or networks connected to the Website, whether partly or fully and whether permanently or temporarily.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>o.</strong> Email or otherwise transmit any content or material that contains software viruses, malware, spyware or any other computer code, files or programs designed to interrupt, destroy, disrupt or limit the functionality of the Website or of any computer software or hardware or telecommunications equipment connected with the Website.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>p.</strong> Use meta tags or code or programs or other devices, tools or technology containing any reference to us or the Website (or any trademark, trade name, service mark, logo or slogan of ours or any Website user) to direct any person to any other website for any purpose.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>    
<strong>q.</strong> Directly or indirectly modify, adapt, sublicense, translate, sell, reverse engineer, decipher, decompile or otherwise disassemble any portion of the Website.
</p>

<p class="prod-title ptag">  
<strong style={{'color':'#003628'}}>7. Content Posted by You</strong>
</p>    
<p class="prod-title ptag">  
<strong>a.</strong> You are solely responsible for any and all content or information (collectively, “Content”) that you post, upload, share, publish, link to, transmit, record, display or otherwise make available (hereinafter, “post”) on the Website or transmit to other Website users, if permitted to do so by us.
</p>
<p class="prod-title ptag">  
<strong>b.</strong> We do not verify the accuracy or truth of any Content you post on or through the Website. We are not the publisher of such Content and only provide you with a technology platform to facilitate the Website users to post such Content. We assume no responsibility or liability of any sort for providing a technology platform to our Website users to facilitate them to post their Content. To protect the integrity of the Website, we reserve the right to exercise editorial control over your Content, including the right to delete such Content and block any user from accessing the Website, whether temporarily or permanently.
</p>
<p class="prod-title ptag">  
<strong>c.</strong> You shall not post on the Website or to any other Website user, any offensive, inaccurate, incomplete, inappropriate, abusive, obscene, profane, threatening, intimidating, harassing, racially offensive, or illegal Content that infringes or violates ours or any person’s rights (including intellectual property rights, and rights of privacy and publicity).
</p>
<p class="prod-title ptag">  
<strong>d.</strong> You represent and warrant that (i) all information and Content that you submit upon creation of your Website account is accurate and truthful and that you will promptly update any information provided by you that subsequently becomes inaccurate, incomplete, misleading or false and (ii) you have the right to post the Content on the Website and grant the licenses as agreed in these Terms of Use.
</p>
<p class="prod-title ptag">  
<strong>e.</strong> By posting Content on the Website, you automatically grant us and to our affiliates, licensees and successors, an irrevocable, perpetual, non-exclusive, transferable, sub-licensable, fully paid-up/royalty-free, worldwide right and license to. <br/>
</p>
<p style={{'paddingLeft':'18px','lineHeight':'20px'}}>
<strong>(i)</strong> use, copy, store, perform, display, reproduce, record, play, adapt, modify and distribute the Content.<br/> 
<strong>(ii)</strong> create derivative works of the Content or incorporate the Content into other works. <br/>
<strong>(iii)</strong> grant and authorize sublicenses of the foregoing in any media now known or hereafter created. You represent and warrant that any posting and use of your Content by us will not infringe or violate the rights of any third party.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
<strong>f.</strong> Your use of the Website, including all Content you post, must comply with all applicable laws and regulations. You shall not post, upload, modify, display, publish, transmit, update, share or otherwise make available Content that violates any applicable law of these Terms of Use.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
<strong>g.</strong> We assume no responsibility or liability for any deletion of or failure to store any of your Content.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>8. Disclaimer of Warranty</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
We may edit, delete or modify any of the terms and conditions contained in this agreement, at any time and in our sole discretion, by posting a notice or a new agreement on our site. Your continued participation in our program, visit and shopping on our site following our posting of a change notice or a new agreement on our site will constitute binding acceptance of the change.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>Acknowledgment of Rights</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
<strong>a.</strong> To the maximum extent permitted by applicable law. we have provided the Website on an "AS IS" and "AS AVAILABLE" and “BEST EFFORTS” basis and grant no warranties of any kind, whether express, implied, direct, indirect, statutory or otherwise with respect to the website or the result of the website (including all information and content contained therein), including any implied warranties of correctness, validity, accuracy, appropriateness, fitness, merchantability or compatibility for a particular purpose or outcome or non-infringement. We do not warrant that the use of the Website will always be secured, uninterrupted, available, error-free or will meet your requirements or expectations, or that any defects in the Website or in the products offered from the Website will be corrected. We disclaim liability for, and no warranty is made with respect to, the connectivity and availability of the Website at all times and the results of the use of the Website.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
<strong>b.</strong> Opinions, advice, statements, offers, or other information or content made available through the Website, but not directly by us, are those of the respective Website users, and should not necessarily be relied upon. Such users are solely responsible for such content. We do not (i) guarantee the accuracy, completeness or usefulness of any information provided on the Website, or (ii) adopt, endorse or accept responsibility for the accuracy or reliability of any opinion, advice, or statement made by any party other than us.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
<strong>c.</strong> From time to time, we may offer new features or tools which our Website users may experiment or experience. Such features or tools are offered solely for experimental purposes and without any warranty of any kind, and may be modified or discontinued at our sole discretion. The provisions of this Disclaimer of Warranty clause shall apply with full force to such features and tools.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
<strong>d.</strong> We accept no responsibility for any damage, loss, liabilities, injury or disappointment incurred or suffered by you as a result of the use or non-use of the Website.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>9. Limitation of Liability</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
To the maximum extent permitted by applicable law, in no event will we be liable for any incidental, special, consequential or indirect damages arising out of or relating to the use or inability to use the website, result of using the website including, without limitation, damages for recommendation of the website, loss or corruption of data or programs, service interruptions and procurement of substitute services, even if we know or have been advised of the possibility of such damages. To the maximum extent permitted by applicable law, under no circumstances will we be liable for any liquidated or punitive damages in connection with the use or non-use of the website, including without limitation, bodily injury, emotional distress, financial loss and/or any other damages resulting from therefrom.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>10. Indemnification</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
You agree to indemnify, defend and hold harmless The Organic Forest and its affiliates and subsidiaries and each of their officers, directors, employees, agents, consultants, partner sites and related third parties, from and against any and all losses, claims, costs, liabilities and expenses (including reasonable attorneys’ fees) relating to or arising out of your use of the Website, including but not limited to (i) any violation by you of these Terms of Use, or (ii) any action arising from the content that you publish/post on the Website that infringes the intellectual property rights (e.g. copyright, trade secrets, trademark or patent) of any third party, or (iii) any content or communication that denigrates, libels or invades the privacy right of any third party. We reserve the right, at our own cost, to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, and you will co-operate fully in asserting any available defences in such case.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>11. Product Pricing and Description
</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
The product price displayed on our Website represents the full retail price listed for the product itself. Such list price is a comparative price estimate and may or may not represent the prevailing price in every area on any particular day. For certain products that are offered as a set, the list price may represent an “open-stock” price, which means the aggregate of the estimated or suggested retail price for each of the products included in the set. Where a product is offered for sale by us, the list price will be provided by us. In cases of mispriced products in our catalogues where a product’s correct price is higher than our listed price, we will, at our discretion, either contact you for instructions before shipping or cancelling your order and notify you of such cancellation.
We do not warrant that product descriptions or other content on this Website is accurate, complete, reliable, current, or error-free. If a product offered on our Website is not as described, your sole remedy is to return it in unused condition as per our Returns & Exchange policy as per Clause 14 here of.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>12. Payments</strong>   
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
The Organic Forest uses third party payment gateways for processing payment of all orders from the Website. The Organic Forest is not responsible for any delays or cancellation of orders due to any payment related issues. As we do not control such third party’s technology, platforms, systems and processes, we cannot be held liable or responsible for any error or fault at the end of such third party payment gateway service providers.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}> 
<strong>We accept payments for product orders placed on the Website through any of the following modes:</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> Bank transfer – debit card or credit card.<br/>
<strong>b.</strong> Cash on Delivery (COD).<br/>
<strong>c.</strong> Any other modes accepted?. </p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
Please note the following for orders placed through Cash on Delivery (COD) basis:
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> To avail COD service, the product items in the cart should be eligible for COD.<br/>
<strong>b.</strong> You will not open the product package without making the payment at the time of delivery. If done so then the delivery will be deemed to be accepted by you and will not be eligible for cancellation, refund or exchange.<br/>
<strong>c.</strong> If the product or packaging has been tampered with, please do not accept delivery of the order.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>4. No credit/debit cards will be accepted at the time of delivery under the COD method.</strong>
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>5. We cannot accept COD in case your pin code is not serviceable*.</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
If you want to know whether your area is serviceable or not, you can call us on <strong style={{'color':'#003628'}}>+91 9811-877-955</strong> (between 9 am – 6 pm, Monday - saturday) or email us at <strong style={{'color':'#003628'}}>care@theorganicforest.in</strong> and provide your PIN code. Unless otherwise agreed, payment must first be received by The Organic Forest prior to the acceptance of any order. Invoices are due and payable within the time period noted on the applicable invoice, calculated from the date of the invoice. The Organic Forest has all the discretion to cancel or deny any orders. The Organic Forest is not responsible for any pricing, typographical or other error concerning the products and reserves the right to cancel any orders arising from any such errors.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>13. Shipping Policy</strong>    
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
The Organic Forest is currently shipping all over India. Further, we are working on enabling international shipping soon. The Organic Forest understands how important it is to receive your purchased products in the finest condition and on time; hence our team works round the clock to try and get your products across to you in the shortest timeframe possible.
Shipping Fee: We offer free shipping of products for orders above Rs. 599/- in one single transaction. For any order below Rs. 599/-, we levy a shipping charge of Rs 99/- per order.
Shipping Duration: All products are shipped out between 2-10 working days depending on the product category and availability. 
</p>
<p class="prod-title ptag">
<strong>Note:</strong> Please note that we ship all the products in your order under one transaction together as one shipment.
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
For circumstances and situations beyond our control, there may be a delay in shipping and delivering your products within the aforesaid duration. In such cases, though we shall not be held liable, nevertheless, we will ensure delivery in the earliest possible time frame.
If you want to know whether your area is serviceable or not, you can call us on <strong style={{'color':'#003628'}}>+91 9811-877-955</strong> (between 9 am – 6 pm, Monday – Saturday) or email us at <strong style={{'color':'#003628'}}>care@theorganicforest.in</strong> provide your PIN code. We will inform you within working hours from receipt of your request.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>14. Returns and Exchanges</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>We follow a ‘no questions asked’ return or exchange policy provided the following are fulfilled:</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'18px','lineHeight':'20px'}}>
<strong>a.</strong> Image of the product/s is/are shared by you if asked by us.
<strong>b.</strong> You must raise a request by emailing us on care@theorganicforest.in within a maximum period of 48 hours of receipt of your order.
<strong>c.</strong> Product packaging, labels and tags are intact and are not tampered with.
<strong>d.</strong> Product must be in perfect condition.
<strong>e.</strong> Request for product to be exchanged will be processed only once the earlier product shipped has been received by us and has been checked for quality and damages, if any.
<strong>f.</strong> Refunds will be processed only for damaged or defective products in the form of store credit. Please note that the shipping charge of Rs. 99/-, if levied, will not be refunded.
<strong>g.</strong> For prepaid orders, in case of order cancellations or orders that go to abandoned cart, refund will be processed back to you via our payment gateway. Timeline for such refund will be about 5-7 working days.
<strong>h.</strong> Once you have requested for a return/exchange, such product will be collected by us within 7 working days.
<strong>i.</strong> If you choose to self-ship the products to us at any of our authorized locations, they should be dispatched within 48 hours after the return request is raised and packed securely to prevent any damage during transit, the loss or damage thereto, if any, shall be to your sole account.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>15. Cancellations </strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
The whole order amount for the products ordered by you will be refunded if the order is cancelled, lost or undelivered to your preferred location.
We reserve the right to cancel any order without any explanation to you for doing so for circumstances and situations beyond our control or where the delivery cannot be met*. We will ensure that any communication of cancellation of an order or any applicable refund will be made by in a reasonable time.
If you want to know whether your area is serviceable or not, you can call us on +91 9811-877-955 (between 9 am – 6 pm, Monday - Saturday) or email us at care@theorganicforest.in provide your PIN code.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>16. Intellectual Property</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
The Organic Forest and its licensors expressly reserve all intellectual property rights in all content, programs, products, processes, technology and other materials, which appear on the Website. The Website is proprietary to us and is our intellectual property. All intellectual property rights in and to the content available on the Website belongs to us/our licensors. Any use of the Website or its contents, including copying or storing any of them, whether in whole or part, other than for your own personal and non-commercial use, is prohibited without our express permission. You hereby acknowledge that you will not acquire any right, title and/or interest in or to the Website except as expressly set forth in these Terms of Use.
The The Organic Forest name and logos and all related product names, design marks and slogans are the trademarks or service marks of Far Left Retail Private Limited. All other marks are the property of their respective sellers . Access to the Website does not authorize you to use any name, logo or mark in any manner whatsoever.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>17. Third Party Links</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
The Website may have reference to any names, marks, products / services of third parties or hypertext links to third party websites. Such information and links are provided solely as a convenience to you and do not in any way constitute or imply The Organic Forest’s endorsement, sponsorship or recommendation of any such third party, information, product or service. We will not be responsible for the content of any third party website and do not make any representation regarding the content or accuracy of material on such website. If you decide to use or link to any such third party website, you do so entirely at your own risk.
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>18. Modifications to the Website
</strong>
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
We reserve the right at any time to modify or discontinue, whether temporarily or permanently, the Website (or any part thereof) with or without notice. You agree that we shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Website. Any access or usage by you of the Website shall imply that you have accepted any new or modified Terms of Use. Please re-visit these Terms of Use from time to time to stay abreast of any changes that we may introduce.
</p>
<p class="prod-title ptag">
<strong style={{'color':'#003628'}}>19. Miscellaneous</strong> 
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
<strong>a.</strong> Entire Agreement: These Terms of Use constitutes the entire agreement between you and The Organic Forest regarding the subject matter hereof, and replaces and supersedes any and all prior agreements/ understandings/ correspondences, whether written or oral, between you and us.<br/>
<strong>b.</strong> Amendment: We reserve the right to amend these Terms of Use from time to time at our sole discretion. Any such amendments will be applicable to all persons viewing/accessing/using the Website once the revisions have been posted onto the same. You should therefore check the Website from time to time to review the current Terms of Use.<br/>
<strong>c.</strong> Survival: Termination or suspension of your Website account shall not affect those provisions hereof that by their nature are intended to survive such termination or suspension.<br/>
<strong>d.</strong> Governing Law and Jurisdiction: These Terms of Use shall be governed and construed in accordance with the laws of India in relation to any legal action or proceedings to enforce the same and any competent courts situated at Mumbai shall have the exclusive jurisdiction.<br/>
<strong>e.</strong> No Assignment: These Terms of Use are personal to you. You cannot assign any of your rights or obligations, whether partly or fully, to any third party.<br/>
<strong>f.</strong> Severability: If any provisions of these Terms of Use are held invalid or unenforceable under applicable law, such provision will be inapplicable, but the remainder will continue in full force and effect.<br/>
<strong>g.</strong> Waiver: No waiver of any term, provision or condition of this Agreement whether by conduct or otherwise in any one or more instances shall be deemed to be or construed as a further or continuing waiver of any such term, provision or condition or of any other term, provision or condition of these Terms of Use.<br/>
</p>
<p class="prod-title ptag"> 
<strong style={{'color':'#003628'}}>20. Contact Us</strong> 
</p>
<p class="prod-title ptag" style={{'paddingLeft':'10px','lineHeight':'20px'}}>
You can reach us by email at <strong style={{'color':'#003628'}}>care@theorganicforest.in</strong> by mobile at +91 9811-877-955 between Monday – Saturday, 9 am – 6 pm in case of any queries. We strive to answer and resolve all queries within  working hours.
</p>

</div>
</div>
</div>
</div>

)
}
}